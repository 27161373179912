import React from 'react';
import ReactDOM from 'react-dom/client';

import { 
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,

} from 'react-router-dom'
import './assets/styles/bootstrap.custom.css';  // Add this line
import './assets/styles/index.css';  // Add this line
import App from './App';
import reportWebVitals from './reportWebVitals';
import HomeScreen from './screens/HomeScreen';
import ProductScreen from './screens/ProductScreen';

let REACT_APP_SERVER_URL = process.env.REACT_APP_SERVER_URL;

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<App />} >
      <Route index={true} path='/' element={<HomeScreen />} />
      <Route path={`/product/:id`} element={<ProductScreen />} />
    </Route>
  )
);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router}/>

  </React.StrictMode>
);


reportWebVitals();
